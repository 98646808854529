import React, { Component } from "react";
import { Link } from "react-router-dom";
import { kEndpoint } from "../../config/constants";
const Portfolio_image = (
  <img
    src="/assets/images/portfolio/portfolio-4.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image2 = (
  <img
    src="/assets/images/portfolio/portfolio-5.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image3 = (
  <img
    src="/assets/images/portfolio/portfolio-6.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image4 = (
  <img
    src="/assets/images/portfolio/portfolio-7.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image5 = (
  <img
    src="/assets/images/portfolio/portfolio-8.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image6 = (
  <img
    src="/assets/images/portfolio/portfolio-9.jpg"
    alt="React Creative Agency"
  />
);

const PortfolioListContent = [
  {
    image: Portfolio_image,
    category: "Development",
    title: "Web Design",
    description: "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.",
  },
  {
    image: Portfolio_image2,
    category: "Product Design",
    title: "App Development",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
  {
    image: Portfolio_image3,
    category: "Application",
    title: "Photoshop Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
  {
    image: Portfolio_image4,
    category: "Web Design",
    title: "Website Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
  {
    image: Portfolio_image5,
    category: "Application",
    title: "Web Application",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
  {
    image: Portfolio_image6,
    category: "Photoshop",
    title: "Photo Editing",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  },
];

const PortfolioList = (props) => {
  const { column, styevariation } = props;
  const list = props?.portfolios || [];

  return (
    <>
      {list.map(({ attributes: value }, index) => (
        <div className={`${column}`} key={index}>
          <div className={`im_portfolio ${styevariation}`}>
            <div className="thumbnail_inner">
              <div className="thumbnail">
                <Link to={`portfolios/${value.slug}`}>
                  <img
                    src={kEndpoint + value.featured_image.data?.attributes?.url}
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <div className="content">
              <div className="inner">
                <div className="portfolio_heading">
                  <div className="category_list">
                    <Link to={`portfolios/${value.slug}`}>{value.type}</Link>
                  </div>
                  <h4 className="title">
                    <Link to={`portfolios/${value.slug}`}>{value.title}</Link>
                  </h4>
                </div>
                <div className="portfolio_hover">
                  <p>{value.subtitle}</p>
                </div>
              </div>
            </div>
            <Link
              className="transparent_link"
              to={`portfolios/${value.slug}`}
            ></Link>
          </div>
        </div>
      ))}
    </>
  );
};
export default PortfolioList;

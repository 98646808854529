import React, { Component } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiMail,
  FiCopy,
} from "react-icons/fi";

import { Link } from "react-router-dom";

import BrandingAndDigital from "../../assets/svg/brand.svg";
import VideoProduction from "../../assets/svg/Jtech Icons-03.svg";
import SoftwareDevelopment from "../../assets/svg/Jtech Icons-06.svg";
import MobileApplication from "../../assets/svg/Jtech Icons-04.svg";
import WebHosting from "../../assets/svg/Jtech Icons-02.svg";
import WebHosting2 from "../../assets/svg/Jtech Icons-01.svg";
import { kEndpoint } from "../../config/constants";

// const ServiceList = [

//     {
//         slug:'/branding-and-digital',
//         icon: <img src={VideoProduction}  height={60} />,
//         title: 'Photo & Video Production',
//         description: 'We uncover your business\' unique brand and build effective brand-led strategies.'
//     },
//     {
//         slug:'/branding-and-digital',
//         icon: <img src={BrandingAndDigital}  height={60} />,
//         title: 'Branding & Digital Marketing',
//         description: 'We uncover your business\' unique brand and build effective brand-led strategies.'
//     },
//     {
//         slug:'/software-development',
//         icon: <img src={SoftwareDevelopment}  height={60} />,
//         title: 'Software Development',
//         description: 'we are dedicated to creating powerful, effective and engaging websites.'
//     },
//     {
//         slug:'/mobile-development',
//         icon: <img src={MobileApplication}  height={60} />,
//         title: 'Mobile Development',
//         description: 'Invent, build, integrate, scale and upgrade your applications with JTech!'
//     },
// {
//     slug:'/web-hosting',
//         icon: <img src={WebHosting}  height={60} />,
//         title: 'Website Hosting & Development',
//         description: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in.'
//     },

//     {
//         slug:'/web-hosting',
//         icon: <img src={WebHosting2}  height={60} />,
//         title: 'Website Hosting & Development',
//         description: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for.'
//     },
// ]

const ServiceTwo = ({ services }) => {
  let title = "We can help you succeed.",
    description =
      "Our team will supply a rich IT service experience that will deliver <br/> success to your business or organization, much sooner than you may think.",
    subtitle = "Making your business flourish";
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <span className="subtitle">{subtitle}</span>
              <h2 className="title">{title}</h2>
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: description }}
              ></p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-12 mt--30">
            <div className="row service-main-wrapper">
              {services.map(({ id, attributes }) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={id}>
                  {/* <Link to={"/services/" + attributes.slug}> */}
                  <div className="service service__style--2 text-left">
                    <div className="icon">
                      <img src={attributes.imageUrl} height={60} />
                    </div>
                    <div className="content">
                      <h3 className="title">{attributes.title}</h3>
                      <p>{attributes.subtitle}</p>
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ServiceTwo;

import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
  FaGithub,
  FaPhone,
  FaMailBulk,
  FaVoicemail,
  FaEnvelope,
  FaEnvelopeOpen,
} from "react-icons/fa";
import moment from "moment/moment";
const logoUrl = (
  <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />
);

const socialShare = [
  { social: <FaFacebookF />, link: "https://www.facebook.com/jtech.somalia" },
  { social: <FaGithub />, link: "https://www.github.com/jtechso" },
  { social: <FaInstagram />, link: "https://www.instagram.com/jtech.somalia/" },
  { social: <FaTwitter />, link: "https://twitter.com/Jtech_somalia" },
];

export default function Footer() {
  return (
    <>
      <footer className="footer-area footer-style-01 bg_color--6">
        {/* Start Call to Action Area  */}
        <div className="im-call-to-action-area ptb--70 im-separator">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                <div className="inner">
                  <h2 className="text-white mb--0">
                    Enough Talk, Let's Make Your Business Flourish
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                <div className="call-to-cation-tbn text-left text-lg-right mt_md--20 mt_sm--20">
                  <Link
                    className="btn-default btn-large btn-border btn-opacity"
                    to="/help"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Call to Action Area  */}

        {/* Start Footer Area  */}
        <div className="footer-wrapper ptb--70">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="ft-text">
                  <div className="logo">
                    <Link to="/">{logoUrl}</Link>
                    <p style={{ marginTop: "6px" }}>
                      We believe that the prospect presented by technology has
                      never been more spacious, and because of that opportunity,
                      JTech will proceed to be one of the leading ICT Centers in
                      East Africa.
                    </p>
                  </div>
                  <p>
                    Copyright © {moment().format("YYYY")}{" "}
                    <Link to="/">Jamhuriya Technology Solutions | Jtech</Link>
                  </p>
                </div>
              </div>

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-xl-2 offset-xl-1 col-md-6 col-sm-6 col-12 mt_mobile--40">
                <div className="footer-link">
                  <h4>Quick Links</h4>
                  <ul className="ft-link">
                    <li>
                      <Link to="/portfolios">Portfolios</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/blogs">Our Blogs</Link>
                    </li>
                    <li>
                      <Link to="/help">Get Help</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* End Single Widget  */}

              {/* Start Single Widget  */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                <div className="footer-link">
                  <h4>Say Hello 👋</h4>
                  <ul className="ft-link">
                    <li>
                      <FaEnvelope />{" "}
                      <a href="mailto:info@jtech.so">info@jtech.so</a>
                    </li>
                    <li>
                      <FaEnvelope />{" "}
                      <a href="mailto:support@jtech.so">support@jtech.so</a>
                    </li>
                    <li>
                      <FaPhone />{" "}
                      <a href="tel:+252615868999">+252 615 868999</a>
                    </li>
                  </ul>

                  <div className="social-share-inner mt--20">
                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                      {socialShare.map((val, i) => (
                        <li key={i}>
                          <a href={`${val.link}`} target="_blank">
                            {val.social}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              {/* End Single Widget  */}
            </div>
          </div>
        </div>
        {/* End Footer Area  */}
      </footer>
    </>
  );
}

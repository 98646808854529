const BlogContent = [
  {
    images: "01",
    title: "What is the Difference between Web and Brand.",
    category: "App Development",
  },
  {
    images: "02",
    title: "A big ticket gone to be an interesting look New York.",
    category: "Jtech Solutions",
  },
  {
    images: "03",
    title: "Getting tickets to the big show have a closer look.",
    category: "Photoshop",
  },
  {
    images: "04",
    title: " Getting tickets to the big show",
    category: "Development",
  },
  {
    images: "05",
    title: "A big ticket gone to be an interesting ",
    category: "Management",
  },
  {
    images: "06",
    title: "The Home of the Future Could Bebes",
    category: "Design",
  },
  {
    images: "07",
    title: "The Home of the Future Could Bebes",
    category: "Design",
  },
];

export default BlogContent;

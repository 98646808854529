import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu, FiArrowRight } from "react-icons/fi";

import Services from "../../data/services.json";
import Softwares from "../../data/softwares.json";

import { FaWhatsapp } from "react-icons/fa";
import { SUB_DOMAIN } from "../../data/constants";

const Header = (props) => {
  const [serviceSubmenuState, setServiceSubmenu] = useState(false);

  const menuTrigger = () => {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  };
  const CLoseMenuTrigger = () => {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  };

  // useEffect(() => {

  // }, [])

  var elements = document.querySelectorAll(".has-droupdown > a");
  for (var i in elements) {
    if (elements.hasOwnProperty(i)) {
      elements[i].onclick = function () {
        this.parentElement.querySelector(".submenu").classList.toggle("active");
        this.classList.toggle("open");
      };
    }
  }

  const { logo, color = "default-color" } = props;
  let logoUrl;
  if (logo === "light") {
    logoUrl = (
      <img src="/assets/images/logo/logo-light.png" alt="ICT Solutions" />
    );
  } else if (logo === "dark") {
    logoUrl = (
      <img src="/assets/images/logo/logo-dark.png" alt="ICT Solutions" />
    );
  } else if (logo === "symbol-dark") {
    logoUrl = (
      <img src="/assets/images/logo/logo-symbol-dark.png" alt="ICT Solutions" />
    );
  } else if (logo === "symbol-light") {
    logoUrl = (
      <img
        src="/assets/images/logo/logo-symbol-light.png"
        alt="ICT Solutions"
      />
    );
  } else {
    logoUrl = <img src="/assets/images/logo/logo.png" alt="ICT Solutions" />;
  }

  window.addEventListener("scroll", function () {
    var value = window.scrollY;
    let logo = document.getElementById("header-logo");
    if (value > 50) {
      logo.firstChild.src = "/assets/images/logo/logo-d.png";
      document.querySelector(".header--fixed").classList.add("sticky");
    } else {
      logo.firstChild.src = "/assets/images/logo/logo.png";
      document.querySelector(".header--fixed").classList.remove("sticky");
    }
  });

  return (
    <header
      onMouseLeave={() => setServiceSubmenu(false)}
      className={`header-area header--fixed formobile-menu header--transparent ${color}`}
    >
      <div className="header-wrapper container" id="header-wrapper">
        <div className="header-left">
          <div className="logo">
            <Link id="header-logo" to="/">
              {logoUrl}
            </Link>
          </div>
        </div>
        <div className="header-right">
          <nav className="mainmenunav d-lg-block">
            <ul className="mainmenu">
              <li onMouseEnter={() => setServiceSubmenu(false)}>
                <Link to="/">Home</Link>
              </li>
              {/* <li
                className="d-none d-lg-block submenu-panel-menu"
                // onClick={() => setServiceSubmenu(!serviceSubmenuState)}
                // onMouseEnter={() => setServiceSubmenu(true)}
              >
                <Link to="/services">Services</Link>
              </li> */}
              {/* <li className="d-block d-lg-none has-droupdown">
                <a href="#">Services</a>
                <ul className="submenu">
                  {Services?.map((service, idx) => (
                    <li key={idx}>
                      <Link to={`/services/${service.slug}`}>
                        {service.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li> */}
              <li onMouseEnter={() => setServiceSubmenu(false)}>
                <Link to="/services">Services</Link>
              </li>
              <li onMouseEnter={() => setServiceSubmenu(false)}>
                <a href={SUB_DOMAIN}>Trainings</a>
              </li>
              <li onMouseEnter={() => setServiceSubmenu(false)}>
                <Link to="/portfolios">Portfolios</Link>
              </li>

              <li onMouseEnter={() => setServiceSubmenu(false)}>
                <Link to="/blogs">Blogs</Link>
              </li>

              <li onMouseEnter={() => setServiceSubmenu(false)}>
                <Link to="/help">Help</Link>
              </li>
            </ul>
          </nav>

          <div className="header-btn">
            <a
              className="btn-default btn-border btn-opacity"
              target="_blank"
              rel="noopener noreferrer"
              href="https://my.jtech.so/login"
            >
              <span>Client Area</span>
            </a>
          </div>
          {/* Start Hamburger Menu  */}
          <div className="humberger-menu d-block d-lg-none pl--20 pl_sm--10">
            <span onClick={menuTrigger} className="menutrigger text-white">
              <FiMenu />
            </span>
          </div>
          {/* End Hamburger Menu  */}
          <div className="close-menu d-block d-lg-none">
            <span onClick={CLoseMenuTrigger} className="closeTrigger">
              <FiX />
            </span>
          </div>
        </div>
        <div
          className={`submenu-panel row w-100  p-4 mx-auto ${
            serviceSubmenuState ? " " : "d-none"
          }`}
          onMouseLeave={() => setServiceSubmenu(false)}
        >
          <div className="col-6">
            <h1 className="text-secondary">Services</h1>
            {Services?.map((attributes, idx) => (
              <div className="panel-service-item d-flex" key={idx}>
                <div className="service--icon bg-transparent">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                </div>
                \
                <div className="ml-3">
                  <h6 className="mb-0 text-secondary">
                    {attributes?.title} <FiArrowRight className="d-none ml-1" />
                  </h6>
                  <p>{attributes?.subtitle}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="col-6">
            <h5 className="text-secondary">Available Softwares</h5>

            {Softwares?.map((software, idx) => (
              <div className="panel-service-item d-flex" key={idx}>
                <div className="service--icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <h6 className="mb-0 text-secondary">
                    {software.name} <FiArrowRight className="d-none ml-1" />
                  </h6>
                  <p>{software.description}</p>
                </div>
              </div>
            ))}

            <a
              href="https://wa.me/+252615868999"
              target="_blank"
              className="btn-default btn-border mt-3"
            >
              Request a Quote <FaWhatsapp className="ml-1 mb-1" />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;

exports.services = [
  {
    id: 1,
    attributes: {
      title: "Software Development",
      subtitle: "Sophisticated Demand-driven Solutions.",
      section_title: "Providing Service",
      description:
        '<p style="margin-left:0px;">But the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.</p><p style="margin-left:0px;">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn\'t anything embarrassing</p><ul><li>Yet this above sewed flirted opened ouch</li><li>Goldfinch realistic sporadic ingenuous</li><li>Abominable this abidin far successfully then like piquan</li><li>Risus commodo viverra</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing</li></ul>',
      videurl: "https://www.youtube.com/watch?v=wFpPJi8px6o",
      createdAt: "2022-11-26T10:07:20.991Z",
      updatedAt: "2023-01-10T09:32:50.271Z",
      publishedAt: "2022-11-26T12:30:21.131Z",
      slug: "software-development",
      imageUrl: "/assets/images/icons/icon-09.png",
      featured_image: {
        data: {
          id: 2,
          attributes: {
            name: "1920x600.jpg",
            alternativeText: null,
            caption: null,
            width: 1920,
            height: 600,
            formats: {
              thumbnail: {
                name: "thumbnail_1920x600.jpg",
                hash: "thumbnail_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 245,
                height: 77,
                size: 46.08,
                url: "/uploads/thumbnail_1920x600_96a184de8d.png",
              },
              small: {
                name: "small_1920x600.jpg",
                hash: "small_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 500,
                height: 156,
                size: 146.24,
                url: "/uploads/small_1920x600_96a184de8d.png",
              },
              medium: {
                name: "medium_1920x600.jpg",
                hash: "medium_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 750,
                height: 234,
                size: 288.23,
                url: "/uploads/medium_1920x600_96a184de8d.png",
              },
              large: {
                name: "large_1920x600.jpg",
                hash: "large_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 1000,
                height: 313,
                size: 477.22,
                url: "/uploads/large_1920x600_96a184de8d.png",
              },
            },
            hash: "1920x600_96a184de8d",
            ext: ".png",
            mime: "image/png",
            size: 749.89,
            url: "/assets/images/about.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2022-11-26T10:05:54.723Z",
            updatedAt: "2022-11-26T10:05:54.723Z",
          },
        },
      },
      videoThumbnail: {
        data: null,
      },
      icon: {
        data: null,
      },
    },
  },
  {
    id: 3,
    attributes: {
      title: "Website Development",
      subtitle: "Lightning-fast, interactive and engaging websites.",
      section_title: null,
      description:
        '<p style="margin-left:0px;">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form have suffered alteration in some form.</p><p style="margin-left:0px;">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p><ul><li>5 PPC Campaigns 5 PPC Campaigns</li><li>Digital Marketing Digital Marketing</li><li>Marketing Agency Marketing Agency</li><li>Seo Friendly Seo Friendly</li></ul>',
      videurl: null,
      createdAt: "2022-11-26T14:54:47.621Z",
      updatedAt: "2023-01-10T09:33:09.091Z",
      publishedAt: "2022-11-26T14:54:49.605Z",
      slug: "website-development",
      imageUrl: "/assets/images/icons/icon-10.png",
      featured_image: {
        data: null,
      },
      videoThumbnail: {
        data: null,
      },
      icon: {
        data: {
          id: 4,
          attributes: {
            name: "Jtech Icons-02.svg",
            alternativeText: null,
            caption: null,
            width: 108,
            height: 103,
            formats: null,
            hash: "Jtech_Icons_02_8b7a242fde",
            ext: ".svg",
            mime: "image/svg+xml",
            size: 3.56,
            url: "/uploads/Jtech_Icons_02_8b7a242fde.svg",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2022-11-26T14:53:57.525Z",
            updatedAt: "2022-11-26T14:54:06.312Z",
          },
        },
      },
    },
  },
  {
    id: 4,
    attributes: {
      title: "Web Hosting & Domains",
      subtitle: "Powerful and versatile hosting servers.",
      section_title: "Providing Service",
      description:
        '<p style="margin-left:0px;">But the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.</p><p style="margin-left:0px;">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn\'t anything embarrassing</p><ul><li>Yet this above sewed flirted opened ouch</li><li>Goldfinch realistic sporadic ingenuous</li><li>Abominable this abidin far successfully then like piquan</li><li>Risus commodo viverra</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing</li></ul>',
      videurl: "https://www.youtube.com/watch?v=wFpPJi8px6o",
      createdAt: "2022-11-27T07:07:53.925Z",
      updatedAt: "2023-01-10T10:55:05.343Z",
      publishedAt: "2022-11-27T07:07:54.860Z",
      slug: "web-hosting-and-domain-registration",
      imageUrl: "/assets/images/icons/icon-08.png",
      featured_image: {
        data: {
          id: 2,
          attributes: {
            name: "1920x600.jpg",
            alternativeText: null,
            caption: null,
            width: 1920,
            height: 600,
            formats: {
              thumbnail: {
                name: "thumbnail_1920x600.jpg",
                hash: "thumbnail_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 245,
                height: 77,
                size: 46.08,
                url: "/uploads/thumbnail_1920x600_96a184de8d.png",
              },
              small: {
                name: "small_1920x600.jpg",
                hash: "small_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 500,
                height: 156,
                size: 146.24,
                url: "/uploads/small_1920x600_96a184de8d.png",
              },
              medium: {
                name: "medium_1920x600.jpg",
                hash: "medium_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 750,
                height: 234,
                size: 288.23,
                url: "/uploads/medium_1920x600_96a184de8d.png",
              },
              large: {
                name: "large_1920x600.jpg",
                hash: "large_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 1000,
                height: 313,
                size: 477.22,
                url: "/uploads/large_1920x600_96a184de8d.png",
              },
            },
            hash: "1920x600_96a184de8d",
            ext: ".png",
            mime: "image/png",
            size: 749.89,
            url: "/uploads/1920x600_96a184de8d.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2022-11-26T10:05:54.723Z",
            updatedAt: "2022-11-26T10:05:54.723Z",
          },
        },
      },
      videoThumbnail: {
        data: null,
      },
      icon: {
        data: null,
      },
    },
  },
  {
    id: 5,
    attributes: {
      title: "Mobile Applications Development",
      subtitle: "User-friendly & Engaging Android & iOS Apps",
      section_title: "Providing Service",
      description:
        '<p style="margin-left:0px;">But the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.</p><p style="margin-left:0px;">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn\'t anything embarrassing</p><ul><li>Yet this above sewed flirted opened ouch</li><li>Goldfinch realistic sporadic ingenuous</li><li>Abominable this abidin far successfully then like piquan</li><li>Risus commodo viverra</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing</li></ul>',
      videurl: "https://www.youtube.com/watch?v=wFpPJi8px6o",
      createdAt: "2023-01-10T09:34:37.362Z",
      updatedAt: "2023-01-15T09:23:59.789Z",
      publishedAt: "2023-01-10T09:35:00.658Z",
      slug: "mobile-app-development",
      imageUrl: "/assets/images/icons/icon-07.png",
      featured_image: {
        data: {
          id: 2,
          attributes: {
            name: "1920x600.jpg",
            alternativeText: null,
            caption: null,
            width: 1920,
            height: 600,
            formats: {
              thumbnail: {
                name: "thumbnail_1920x600.jpg",
                hash: "thumbnail_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 245,
                height: 77,
                size: 46.08,
                url: "/uploads/thumbnail_1920x600_96a184de8d.png",
              },
              small: {
                name: "small_1920x600.jpg",
                hash: "small_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 500,
                height: 156,
                size: 146.24,
                url: "/uploads/small_1920x600_96a184de8d.png",
              },
              medium: {
                name: "medium_1920x600.jpg",
                hash: "medium_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 750,
                height: 234,
                size: 288.23,
                url: "/uploads/medium_1920x600_96a184de8d.png",
              },
              large: {
                name: "large_1920x600.jpg",
                hash: "large_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 1000,
                height: 313,
                size: 477.22,
                url: "/uploads/large_1920x600_96a184de8d.png",
              },
            },
            hash: "1920x600_96a184de8d",
            ext: ".png",
            mime: "image/png",
            size: 749.89,
            url: "/uploads/1920x600_96a184de8d.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2022-11-26T10:05:54.723Z",
            updatedAt: "2022-11-26T10:05:54.723Z",
          },
        },
      },
      videoThumbnail: {
        data: null,
      },
      icon: {
        data: {
          id: 11,
          attributes: {
            name: "Icons-05.png",
            alternativeText: null,
            caption: null,
            width: 199,
            height: 200,
            formats: {
              thumbnail: {
                name: "thumbnail_Icons-05.png",
                hash: "thumbnail_Icons_05_9ce4cd261d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 155,
                height: 156,
                size: 14.08,
                url: "/uploads/thumbnail_Icons_05_9ce4cd261d.png",
              },
            },
            hash: "Icons_05_9ce4cd261d",
            ext: ".png",
            mime: "image/png",
            size: 5.43,
            url: "/uploads/Icons_05_9ce4cd261d.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2023-01-15T09:18:45.896Z",
            updatedAt: "2023-01-15T09:23:02.458Z",
          },
        },
      },
    },
  },
  {
    id: 6,
    attributes: {
      title: "Branding & Digital Marketing",
      subtitle: "Let's make your brand alive & see the magic!",
      section_title: "Providing Service",
      description:
        '<p style="margin-left:0px;">But the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.</p><p style="margin-left:0px;">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn\'t anything embarrassing</p><ul><li>Yet this above sewed flirted opened ouch</li><li>Goldfinch realistic sporadic ingenuous</li><li>Abominable this abidin far successfully then like piquan</li><li>Risus commodo viverra</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing</li></ul>',
      videurl: "https://www.youtube.com/watch?v=wFpPJi8px6o",
      createdAt: "2023-01-10T10:56:52.613Z",
      updatedAt: "2023-01-15T09:27:14.329Z",
      publishedAt: "2023-01-10T10:57:15.926Z",
      slug: "branding-and-digital-marketing",
      imageUrl: "/assets/images/icons/icon-05.png",
      featured_image: {
        data: {
          id: 2,
          attributes: {
            name: "1920x600.jpg",
            alternativeText: null,
            caption: null,
            width: 1920,
            height: 600,
            formats: {
              thumbnail: {
                name: "thumbnail_1920x600.jpg",
                hash: "thumbnail_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 245,
                height: 77,
                size: 46.08,
                url: "/uploads/thumbnail_1920x600_96a184de8d.png",
              },
              small: {
                name: "small_1920x600.jpg",
                hash: "small_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 500,
                height: 156,
                size: 146.24,
                url: "/uploads/small_1920x600_96a184de8d.png",
              },
              medium: {
                name: "medium_1920x600.jpg",
                hash: "medium_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 750,
                height: 234,
                size: 288.23,
                url: "/uploads/medium_1920x600_96a184de8d.png",
              },
              large: {
                name: "large_1920x600.jpg",
                hash: "large_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 1000,
                height: 313,
                size: 477.22,
                url: "/uploads/large_1920x600_96a184de8d.png",
              },
            },
            hash: "1920x600_96a184de8d",
            ext: ".png",
            mime: "image/png",
            size: 749.89,
            url: "/uploads/1920x600_96a184de8d.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2022-11-26T10:05:54.723Z",
            updatedAt: "2022-11-26T10:05:54.723Z",
          },
        },
      },
      videoThumbnail: {
        data: null,
      },
      icon: {
        data: null,
      },
    },
  },
  {
    id: 7,
    attributes: {
      title: "Professional ICT Trainings & Internships",
      subtitle: "We offer project-based courses & Intern Opportunities",
      section_title: "Providing Service",
      description:
        '<p style="margin-left:0px;">But the majority have suffered alteration in some form, by injected humour, or randomised words which don\'t look even slightly believable.</p><p style="margin-left:0px;">If you are going to use a passage of Lorem Ipsum. You need to be sure there isn\'t anything embarrassing</p><ul><li>Yet this above sewed flirted opened ouch</li><li>Goldfinch realistic sporadic ingenuous</li><li>Abominable this abidin far successfully then like piquan</li><li>Risus commodo viverra</li><li>Lorem ipsum dolor sit amet, consectetur adipiscing</li></ul>',
      videurl: "https://www.youtube.com/watch?v=wFpPJi8px6o",
      createdAt: "2023-01-10T10:57:35.536Z",
      updatedAt: "2023-01-10T12:01:02.825Z",
      publishedAt: "2023-01-10T10:57:37.647Z",
      slug: "ict-trainings",
      imageUrl: "/assets/images/icons/icon-06.png",
      featured_image: {
        data: {
          id: 2,
          attributes: {
            name: "1920x600.jpg",
            alternativeText: null,
            caption: null,
            width: 1920,
            height: 600,
            formats: {
              thumbnail: {
                name: "thumbnail_1920x600.jpg",
                hash: "thumbnail_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 245,
                height: 77,
                size: 46.08,
                url: "/uploads/thumbnail_1920x600_96a184de8d.png",
              },
              small: {
                name: "small_1920x600.jpg",
                hash: "small_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 500,
                height: 156,
                size: 146.24,
                url: "/uploads/small_1920x600_96a184de8d.png",
              },
              medium: {
                name: "medium_1920x600.jpg",
                hash: "medium_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 750,
                height: 234,
                size: 288.23,
                url: "/uploads/medium_1920x600_96a184de8d.png",
              },
              large: {
                name: "large_1920x600.jpg",
                hash: "large_1920x600_96a184de8d",
                ext: ".png",
                mime: "image/png",
                path: null,
                width: 1000,
                height: 313,
                size: 477.22,
                url: "/uploads/large_1920x600_96a184de8d.png",
              },
            },
            hash: "1920x600_96a184de8d",
            ext: ".png",
            mime: "image/png",
            size: 749.89,
            url: "/uploads/1920x600_96a184de8d.png",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            createdAt: "2022-11-26T10:05:54.723Z",
            updatedAt: "2022-11-26T10:05:54.723Z",
          },
        },
      },
      videoThumbnail: {
        data: null,
      },
      icon: {
        data: null,
      },
    },
  },
];

import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import testimonials from "../data/testimolials.json";

const Testimonial = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row align-items-center">
              <div className="col-lg-6">
                {testimonials.map((testimonial, idx) => (
                  <TabPanel key={idx}>
                    <div className="rn-testimonial-content text-left">
                      <div className="inner">
                        <p>{testimonial.content}</p>
                      </div>
                      <div className="author-info">
                        <h6>
                          <span>{testimonial.name} </span> - {testimonial.title}
                        </h6>
                      </div>
                    </div>
                  </TabPanel>
                ))}
              </div>
              <div className="col-lg-6 mt_md--40 mt_sm--40">
                <TabList className="testimonial-thumb-wrapper">
                  {testimonials.map((testinomial, idx) => (
                    <Tab key={idx}>
                      <div className="testimonial-thumbnai">
                        <div className="thumb">
                          <img
                            src={
                              testinomial.image.length
                                ? testinomial.image
                                : "/assets/images/client/testimonial-1.jpg"
                            }
                            alt="Testimonial Images"
                          />
                        </div>
                      </div>
                    </Tab>
                  ))}
                </TabList>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Testimonial;

import React from "react";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin } from "react-icons/fi";
import GoogleMapReact from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";
import BrandTwo from "../elements/BrandTwo";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const AnyReactComponent = ({ text }) => (
  <img
    width="72"
    height="72"
    src="/assets/images/logo/logo-icon.png"
    alt="Jtech"
  />
);

export default function Contact() {
  const location = {
    center: {
      lat: 2.0333007432759094,
      lng: 45.31978518199693,
    },
    zoom: 19,
  };

  return (
    <>
      <PageHelmet pageTitle="Contact" />

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 bg_image bg_image--15"
        data-black-overlay="6"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">Contact Us Now</h2>
                <p>
                  Caring and satisfying our customers with the best services is
                  what we stand for.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Contact Top Area  */}
      <div className="rn-contact-top-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb--30 text-center">
                <span className="subtitle">Our contact address</span>
                <h2 className="title">Quick Contact Address</h2>
                <p className="description">
                  We are more than happy to welcome you to our company, <br />{" "}
                  kindly contact us in which form you prefer😊
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4 className="title">Contact Phone Number</h4>
                  <p>
                    <a href="tel:+252615868999">+252 615 868999</a>
                  </p>
                  <p>
                    <a href="tel:+252616102387">+252 616 102387</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Our Email Address</h4>
                  <p>
                    <a href="mailto:info@jtech.so">info@jtech.so</a>
                  </p>
                  <p>
                    <a href="mailto:support@jtech.so">support@jtech.so</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4 className="title">Our Location</h4>
                  <p>
                    Makkah Almukarramah Ave,
                    <br /> Mogadishu, Somalia
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}
          </div>
        </div>
      </div>
      {/* End Contact Top Area  */}

      {/* Start Contact Page Area  */}
      <div className="rn-contact-page ptb--120 bg_color--1">
        <ContactTwo />
      </div>
      {/* End Contact Page Area  */}

      {/* Start Contact Map  */}
      {/* <div className="rn-contact-map-area position-relative">
        <div style={{ height: "650px", width: "100%" }}>
          <iframe
            style={{ height: "650px", width: "100%" }}
            src="https://maps.google.com/maps?q=2.0326145,45.3191088&t=k&z=19&ie=UTF8&iwloc=&output=embed"
            frameborder="0"
          ></iframe>
        </div>
      </div> */}
      <div className="rn-contact-map-area position-relative">
        <div style={{ height: "650px", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCVzDko-opdG8ZcYL9EzYYlJtCNlZ0OPBo",
            }}
            defaultCenter={location.center}
            defaultZoom={location.zoom}
          >
            <AnyReactComponent
              lat={location.center.lat}
              lng={location.center.lng}
              text="Jtech Solutions"
            />
          </GoogleMapReact>
        </div>
      </div>
      {/* End Contact Map  */}

      {/* Start Brand Area */}
      <div className="rn-brand-area bg_color--5 ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </>
  );
}

// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File

import "./index.scss";

// Common Layout
// import Layout from "./component/common/App";

import PageScrollTop from "./component/PageScrollTop";

// Home layout
import MainDemo from "./home/MainDemo";
// Element Layout
import Service from "./elements/Service";
import ServiceDetails from "./elements/ServiceDetails";
import About from "./elements/About";
import Contact from "./elements/Contact";
import PortfolioDetails from "./elements/PortfolioDetails";
import Blog from "./elements/Blog";
import BlogDetails from "./pages/blogs/[slug]";
import error404 from "./elements/error404";

import Portfolio from "./blocks/Portfolio";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import PortfolioDetail from "./pages/portfolio/[slug]";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <TawkMessengerReact
            propertyId="6357c892daff0e1306d3dbde"
            widgetId="1gg7eevrh"
          />
          <Switch>
            <Route exact path="/" component={MainDemo} />
            <Route exact path="/services" component={Service} />
            <Route exact path="/help" component={Contact} />
            <Route exact path="/about" component={About} />
            <Route exact path="/portfolios" component={Portfolio} />
            <Route exact path="/portfolios/:slug" component={PortfolioDetail} />
            <Route exact path="/blogs" component={Blog} />
            <Route exact path="/blogs/:slug" component={BlogDetails} />
            <Route exact path="/services/:slug" component={ServiceDetails} />
            <Route path="/404" component={error404} />
            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();

import React, { Component, useEffect, useState } from "react";
import { FiCheck } from "react-icons/fi";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { Redirect, useParams } from "react-router-dom";
import parse from "html-react-parser";
import axios from "axios";

const ServiceDetails = () => {
  const params = useParams();
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios(
        `https://cms.jtech.so/api/services?filters[slug]=${params.slug}&populate=*`
      );
      const { total } = response.data.meta.pagination;
      if (total < 1) throw new Error("No Data Found");

      setData(response.data.data[0]);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchData(), []);

  if (loading) return <h5>Loading</h5>;

  const openModal = () => setOpen(true);

  if (!loading && error) return <Redirect to="/404" />;

  return (
    <React.Fragment>
      {/* Start Pagehelmet  */}
      <PageHelmet pageTitle="Service Details" />
      {/* End Pagehelmet  */}

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 pt_md--100 pb_md--100 pt_sm--100 pb_sm--100 bg_image bg_image--4"
        data-black-overlay="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100 pt_md--50 pt_sm--100">
                <h2 className="title theme-gradient">
                  {data.attributes?.title}
                </h2>
                <p> {data.attributes?.subtitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper */}
      <div className="rn-service-details ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="service-details-inner">
                <div className="inner">
                  {/* Start Single Area */}
                  <div className="row row--35 sercice-details-content align-items-center">
                    <div className="col-lg-6 col-12">
                      <div className="thumb position-relative">
                        <img className="w-100" src={""} alt="Service Images" />
                        <ModalVideo
                          channel="youtube"
                          open={open}
                          videoId="ZOoVOfieAF8"
                          onClose={() => setOpen(false)}
                        />
                        <button className="video-popup" onClick={openModal}>
                          <span className="play-icon"></span>
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="details mt_md--30 mt_sm--30">
                        <div className="section-title">
                          <span className="subtitle">Our service</span>
                          <h2 className="title">
                            {data.attributes?.section_title}
                          </h2>

                          {parse(
                            data.attributes?.description ?? ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End Single Area */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Page Wrapper */}

      {/* Start Pricing Tbale Area  */}
      {data?.packageNames?.data.length ? (
        <div className="rn-pricing-table-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                  <span className="subtitle">Our Budget Plan</span>
                  <h2 className="title">Pricing Plan</h2>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Start PRicing Table Area  */}
              {data?.packageNames?.data?.map(({ attributes }, idx) => (
                <div className="col-lg-4 col-md-6 col-12 mt--30">
                  <div
                    className={
                      "rn-pricing " + (attributes.is_popular ? "active" : "")
                    }
                  >
                    <div className="pricing-table-inner">
                      <div className="pricing-header">
                        <h4 className="title">{attributes.packageName}</h4>
                        <div className="pricing">
                          <span className="price">${attributes.price}</span>
                          <span className="subtitle">
                            USD Per {attributes.priceDuration}
                          </span>
                        </div>
                      </div>
                      <div className="pricing-body">
                        {parse(attributes.features ?? "")}
                      </div>
                      <div className="pricing-footer">
                        <a className="rn-btn" href={attributes.btn_url}>
                          Purchase Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                    <div className="rn-pricing">
                      <div className="pricing-table-inner">
                        <div className="pricing-header">
                          <h4 className="title">Free</h4>
                          <div className="pricing">
                            <span className="price">$45</span>
                            <span className="subtitle">USD Per Month</span>
                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list-style--1">
                            <li>
                              <FiCheck /> 5 PPC Campaigns
                            </li>
                            <li>
                              <FiCheck /> Digital Marketing
                            </li>
                            <li>
                              <FiCheck /> Marketing Agency
                            </li>
                            <li>
                              <FiCheck /> Seo Friendly
                            </li>
                            <li>
                              <FiCheck /> UI/UX designs
                            </li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a className="rn-btn" href="#pricing">
                            Purchase Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                    <div className="rn-pricing active">
                      <div className="pricing-table-inner">
                        <div className="pricing-header">
                          <h4 className="title">Business</h4>
                          <div className="pricing">
                            <span className="price">$45</span>
                            <span className="subtitle">USD Per Month</span>
                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list-style--1">
                            <li>
                              <FiCheck /> 5 PPC Campaigns
                            </li>
                            <li>
                              <FiCheck /> Digital Marketing
                            </li>
                            <li>
                              <FiCheck /> Marketing Agency
                            </li>
                            <li>
                              <FiCheck /> Seo Friendly
                            </li>
                            <li>
                              <FiCheck /> UI/UX designs
                            </li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a className="rn-btn" href="#pricing">
                            Purchase Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              {/* <div className="col-lg-4 col-md-6 col-12 mt--30">
                    <div className="rn-pricing">
                      <div className="pricing-table-inner">
                        <div className="pricing-header">
                          <h4 className="title">Advanced</h4>
                          <div className="pricing">
                            <span className="price">$99</span>
                            <span className="subtitle">USD Per Month</span>
                          </div>
                        </div>
                        <div className="pricing-body">
                          <ul className="list-style--1">
                            <li>
                              <FiCheck /> 5 PPC Campaigns
                            </li>
                            <li>
                              <FiCheck /> Digital Marketing
                            </li>
                            <li>
                              <FiCheck /> Marketing Agency
                            </li>
                            <li>
                              <FiCheck /> Seo Friendly
                            </li>
                            <li>
                              <FiCheck /> UI/UX designs
                            </li>
                          </ul>
                        </div>
                        <div className="pricing-footer">
                          <a className="rn-btn" href="#pricing">
                            Purchase Now
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>  */}
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
      ) : null}
      {/* End Pricing Tbale Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};
export default ServiceDetails;

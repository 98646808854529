import React, { Component } from "react";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FiSend } from "react-icons/fi";

import { Link } from "react-router-dom";
class HomeAbout extends Component {
  render() {
    let title = "Who we are",
      description =
        "Jamhuriya Technology Solutions - Jtech is a professional technology solution service provider and ICT training center founded in 2020 by Jamhuriya University of Science and Technology in Mogadishu, Somalia. Jtech has been established to fill the gap (of quality and creativity) in the field of ICT solutions in our nation and beyond by transforming clients’ business, operating and technology models for the digital era, and offering professional hands-on training to empower individuals and organizations in solving and innovating new ideas.",
      description2 =
        "Put simply, we help clients get their digital challenge solved with the measure needed to remodel their complex organizations to capitalize on the tremendous opportunities the latest technologies make possible, and the speed anticipated by their clients, partners and proprietors.";

    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about.png"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <div className="icon">
                      <FiSend />
                    </div>
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                    {/* <p className="description">{description2}</p> */}

                    <Link to="/about" className="btn-default size-md">
                      <FaArrowAltCircleRight className="mr-2" /> Learn more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default HomeAbout;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { kEndpoint } from "../../../config/constants";
import axios from "axios";
import { ShimmerPostList } from "react-shimmer-effects";

export default function LatestNews() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios(
        "https://cms.jtech.so/api/blogs?pagination[limit]=3&sort[id]=desc&populate=*"
      );
      const { data } = response.data;
      setData(data || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchData(), []);

  return (
    <>
      <div className="rn-blog-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center">
                <span className="subtitle">Explore our updates</span>
                <h2 className="title">Latest News</h2>
                <p className="description">
                  Find and read up-to-the-minute <br /> and in-depth coverage of
                  our events & technology trends.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt--30 mt_sm--40">
            {loading ? (
              <div style={{ width: "100%" }}>
                <ShimmerPostList
                  postStyle="STYLE_FOUR"
                  col={3}
                  row={1}
                  gap={30}
                />
              </div>
            ) : (
              data.map(({ attributes }, i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                  <div className="im_box mt--30">
                    <div className="thumbnail">
                      <Link to={`/blogs/${attributes?.slug}`}>
                        <img
                          className="w-100"
                          src={
                            kEndpoint +
                              attributes?.featured_image?.data?.attributes
                                ?.url ?? ""
                          }
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <div className="content_heading">
                          <div className="category_list">
                            <a to="#">
                              {attributes?.category?.data
                                ?.map((ct) => ct.name)
                                .join(",")}
                            </a>
                          </div>
                          <h4 className="title">
                            <Link to={`/blogs/${attributes?.slug}`}>
                              {attributes?.title}
                            </Link>
                          </h4>
                        </div>
                        <div className="content_footer">
                          <Link
                            to={`/blogs/${attributes?.slug}`}
                            className="rn-btn btn-opacity"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {loading ? null : (
            <div className="mt--30 text-center">
              <Link className="rn-btn btn-border size-md" to="/blogs">
                See More
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export const LoadingComponent = (data) => {
  return (
    <>
      <span>Please, wait...</span>
    </>
  );
};

import { FiCheck } from "react-icons/fi";
import React, { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";

function HostingPlan(props) {
  const { plan, selected } = props;

  useEffect(() => {
    if (plan.isPopular) {
      document.getElementById("plan-" + plan.id).classList.add("active");
    }
  }, []);

  return (
    <div className="col-lg-4 col-md-6 col-12">
      <div className="rn-pricing" id={"plan-" + plan.id}>
        <div className="pricing-table-inner">
          <div className="pricing-header">
            <h4 className="title">{plan.title}</h4>
            <div className="pricing">
              <span className="price">{plan.salePrice}</span>
              <span className="subtitle">{plan.subtitle}</span>
            </div>
          </div>
          <div className="pricing-body">
            <ul className="list-style--2">
              {plan.services.map((service, index) => {
                return <li key={index}>{service}</li>;
              })}
            </ul>
          </div>
          <div className="pricing-footer">
            <a className="rn-btn" href={plan.url} target="_blank">
              Order Now <FaWhatsapp className="ml-1 mb-1" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HostingPlan;

import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiAnchor, FiChevronUp, FiEye, FiTarget } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import AboutComp from "../component/HomeLayout/homeOne/About";
import { Link } from "react-router-dom";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"About"} />
        {/* End Breadcrump Area */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--1">
          <AboutComp />
        </div>
        {/* End About Area */}

        {/* Start Vision & Mission */}
        <div className="rn-columns-area ptb--64 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <div className="single-column custom-color rounded">
                  <div className="icon mb-2">
                    <FiAnchor color="white" size={48} />
                  </div>
                  <h4 className="tilte">Objectives</h4>
                  <p>
                    To automate business processes through today's modern
                    technology and meet business expectations in this digital
                    era by creating sophisticated, reliable and dynamic systems,
                    and promoting businesses through digital marketing strategy.
                    <br />
                    To train individuals and organizations from all across the
                    nation and nurture them with practical courses to solve
                    digital business challenges and innovate new ideas in the
                    workforce. <br />
                    <br />
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                <div className="single-column custom-color custom-color--1 rounded">
                  <div className="icon mb-2">
                    <FiTarget color="white" size={48} />
                  </div>
                  <h4 className="tilte">Mission</h4>
                  <p>
                    To help companies and organizations, small and large, focus
                    on their core activities whilst making the best use of
                    technology to enhance productivity and improve business
                    profitability and efficiency. <br />
                    To provide organizations and individuals technical training
                    and consultancy that helps them succeed in their business
                    endeavors. <br />
                    To develop technology solutions and services that exceeds
                    the market’s expectations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Vision & Mission */}

        {/* Start CounterUp Area */}
        <div className="rn-counterup-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Experts growts</span>
                  <h2 className="title">Our Company Growth</h2>
                  <p className="description">
                    We have grown strength over the past 20 years.
                  </p>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        <div
          className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--18"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="inner">
                  <div className="content-wrapper">
                    <div className="content">
                      <h4 className="theme-gradient">Find Your Work Now</h4>
                      <p>
                        We consider it our duty to make people feel at home in
                        the future, no matter how technology-enabled it becomes.
                        We believe that the prospect presented by technology has
                        never been more spacious, and because of that
                        opportunity, JTech will proceed to be one of the leading
                        ICT Centers in East Africa.
                      </p>
                      <Link className="btn-default" to="/help">
                        Say Hello 👋
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Finding Us Area  */}

        {/* Start Testimonial Area */}
        <div className="rn-testimonial-area bg_color--5 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div>
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center mb--30">
                  <span className="subtitle">Top Clients</span>
                  <h2 className="title">We worked with brands.</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;

import React from "react";
import { Link } from "react-router-dom";
import { kEndpoint } from "../../../config/constants";

function PortfolioItem(props) {
  const { item } = props;
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" key={item.id}>
        <div className="im_portfolio">
          <div className="thumbnail_inner">
            <div className="thumbnail">
              <Link to="/item-details">
                <img
                  src={
                    kEndpoint +
                    item.attributes?.featured_image?.data?.attributes?.url
                  }
                  alt="Image"
                />
              </Link>
            </div>
          </div>
          <div className="content">
            <div className="inner">
              <div className="portfolio_heading">
                <div className="category_list">
                  <Link to="/portfolio-details">{item?.attributes?.type}</Link>
                </div>
                <h4 className="title">
                  <Link to="/portfolio-details">{item?.attributes?.title}</Link>
                </h4>
              </div>
              <div className="portfolio_hover">
                <p>{item?.attributes?.subtitle}</p>
              </div>
            </div>
          </div>
          <Link
            className="transparent_link"
            to={`portfolios/${item?.attributes?.slug}`}
          ></Link>
        </div>
      </div>
    </>
  );
}

export default PortfolioItem;

import React, { Component, useEffect, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import BlogList from "../elements/blog/BlogList";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { ShimmerPostList } from "react-shimmer-effects";

import axios from "axios";

const Blog = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios("https://cms.jtech.so/api/blogs?populate=*");
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchData(), []);

  return (
    <>
      <PageHelmet pageTitle="Blog" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      <Breadcrumb title={"Blog List"} />

      <div className="rn-blog-area ptb--120 bg_color--1">
        <div className="container">
          {loading ? (
            <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} />
          ) : (
            <BlogList data={data} />
          )}
        </div>
      </div>

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <Footer />
    </>
  );
};
export default Blog;

import React, { Component } from "react";

export default function BrandTwo() {
  return (
    <>
      <ul className="brand-style-2">
        <li>
          <img
            src="/assets/images/brand/Coat_of_arms_of_Somalia.svg.png"
            alt="Logo Images"
          />
        </li>
        <li>
          <img src="/assets/images/brand/Hasene-01.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/Mybank-logo.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/Medipark.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/Nova.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/SomBank.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/SomQuiz.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/Al Huda-01.png" alt="Logo Images" />
        </li>
        <li>
          <img src="/assets/images/brand/SITCO-Logo.png" alt="Logo Images" />
        </li>
      </ul>
    </>
  );
}

import React, { Component, useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link, Redirect, useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import parse from "html-react-parser";
import axios from "axios";

const BlogDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isOpen, setOpen] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios(
        `https://cms.jtech.so/api/blogs?filters[slug]=${params.slug}&populate=*`
      );

      const resData = Array.isArray(response.data?.data || {})
        ? response.data.data[0]
        : response.data.data;
      setData(resData);
    } catch (error) {
      setError(error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchData(), []);

  const openModal = () => {
    setOpen(!isOpen);
  };

  if (!data && !loading) return <Redirect to="/404" />;

  if (error) return <Redirect to="/404" />;

  return (
    <>
      <PageHelmet pageTitle="Blog Details" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      {
        <>
          <div
            className="rn-page-title-area pt--120 pb--190 bg_image bg_image--4"
            data-black-overlay="7"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="blog-single-page-title text-center pt--100">
                    <h2 className="title theme-gradient">
                      {data?.attributes?.title ?? ""}
                    </h2>
                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                      <li>
                        <FiClock />
                        {data?.attributes?.Date}
                      </li>
                      <li>
                        <FiUser />
                        {data?.attributes?.author?.data?.attributes?.name ??
                          "Admin"}
                      </li>
                      <li>
                        <FiMessageCircle />0 Comments
                      </li>
                      <li>
                        <FiHeart />
                        Like
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="rn-blog-details pt--110 pb--70 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    {parse(data?.attributes?.content ?? "")}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {data?.attributes?.content ? (
            <div className="blog-comment-form pb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner">
                      <div className="section-title">
                        <span className="subtitle">Have a Comment?</span>
                        <h2 className="title">Leave a Reply</h2>
                      </div>
                      <form className="mt--40" action="#">
                        <div className="row">
                          <div className="col-lg-6 col-md-12 col-12">
                            <div className="rnform-group">
                              <input type="text" placeholder="Name" />
                            </div>
                            <div className="rnform-group">
                              <input type="email" placeholder="Email" />
                            </div>
                            <div className="rnform-group">
                              <input type="text" placeholder="Website" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-12 col-12">
                            <div className="rnform-group">
                              <textarea
                                type="text"
                                placeholder="Comment"
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="blog-btn mt--30">
                              <a className="btn-default" href="#">
                                <span>SEND COMMENT</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      }
      {/* End BLog Comment Form  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </>
  );
};
export default BlogDetails;

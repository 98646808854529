import React, { Component, Fragment, useState } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import HeroSection from "../component/HomeLayout/homeOne/Hero";
import { FaSearch } from "react-icons/fa";
import SearchDomain from "../component/HomeLayout/homeOne/SearchDomain";
import HomeAbout from "../component/HomeLayout/homeOne/HomeAbout";
import LatestNews from "../component/HomeLayout/homeOne/LatestNews";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import axios from "axios";
import { services } from "../data/new_services";

const MainDemo = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const PostList = BlogContent.slice(0, 3);

  return (
    <Fragment>
      <Helmet pageTitle="Jamhuriya Technology Solutions" />

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
        services={services}
      />

      {/* Start Slider Area   */}
      <HeroSection />
      {/* End Slider Area   */}

      {/*start domain search form */}

      <SearchDomain />

      {/*end domain search form */}

      {/* Start About Area */}
      <div className="about-area ptb--120">
        <HomeAbout />
      </div>
      {/* End About Area */}

      <div className="service-area ptb--120  bg_color--5">
        <ServiceTwo services={services} />
      </div>

      {/* Start Portfolio Area */}
      <div className="portfolio-area ptb--120 bg_color--1">
        <Portfolio />
      </div>
      {/* End Portfolio Area */}

      {/* Start CounterUp Area */}
      <div className="rn-counterup-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Experience matters ♡</span>
                <h2 className="title">Our Company Growth</h2>
                <p className="description">
                  To meet our customers’ demands, we give clear navigation.
                </p>
              </div>
            </div>
          </div>
          <CounterOne />
        </div>
      </div>
      {/* End CounterUp Area */}

      {/* Start Testimonial Area */}
      <div className="rn-testimonial-area bg_color--1 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div>
      {/* End Testimonial Area */}

      {/* Start Blog Area */}

      <LatestNews />
      {/* End Blog Area */}

      {/* Start Brand Area */}
      <div className="rn-brand-area ptb--120 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="section-title text-center mb--30">
                <span className="subtitle">Some of the Brands</span>
                <h2 className="title">we worked with 😊</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 mt--20">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div>
      {/* End Brand Area */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </Fragment>
  );
};
export default MainDemo;

import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiMail,
  FiCopy,
  FiCheck,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { services } from "../data/new_services";
import { Link } from "react-router-dom";
import { hostingPlans } from "../data/hosting";
import HostingPlan from "./service/HostingPlan";

export default function Service() {
  return (
    <React.Fragment>
      <PageHelmet pageTitle="Service" />
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Service"} />
      {/* End Breadcrump Area */}

      <div className="service-area ptb--120 bg_color--5">
        <div className="container">
          <div className="row service-main-wrapper">
            {services.map(({ id, attributes }) => (
              <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={id}>
                {/* /services/" + attributes.slug */}
                <a to="#">
                  <div className="service service__style--2 text-left">
                    <div className="icon">
                      <img src={attributes.imageUrl} height={60} />
                    </div>
                    <div className="content">
                      <h3 className="title">{attributes.title}</h3>
                      <p>{attributes.subtitle}</p>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="service-area ptb--120 mb--30 bg_color--1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title mb--30 text-center">
                <span className="subtitle">Affordable Hosting Plans</span>
                <h2 className="title">Choose Your Plan Now 👇</h2>
                <p className="description">
                  The maximum-possible performance for your websites and the
                  minimum amount of fuss.
                </p>
              </div>
            </div>
          </div>
          <div className="row mt--30">
            {hostingPlans.map((plan) => (
              <HostingPlan plan={plan} key={plan.id} />
            ))}
          </div>
        </div>
      </div>

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioList from "../elements/portfolio/PortfolioList";
import { slickDot, portfolioSlick2 } from "../page-demo/script";

import ReactPaginate from "react-paginate";
import { ShimmerPostList } from "react-shimmer-effects";
import axios from "axios";

const Portfolio_image = (
  <img
    src="/assets/images/portfolio/portfolio-4.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image2 = (
  <img
    src="/assets/images/portfolio/portfolio-5.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image3 = (
  <img
    src="/assets/images/portfolio/portfolio-6.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image4 = (
  <img
    src="/assets/images/portfolio/portfolio-7.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image5 = (
  <img
    src="/assets/images/portfolio/portfolio-8.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image6 = (
  <img
    src="/assets/images/portfolio/portfolio-9.jpg"
    alt="React Creative Agency"
  />
);

const list = [
  {
    image: Portfolio_image,
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: Portfolio_image2,
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: Portfolio_image3,
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: Portfolio_image4,
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: Portfolio_image5,
    category: "Development",
    title: "Getting tickets to the big show",
  },
  {
    image: Portfolio_image6,
    category: "Development",
    title: "Getting tickets to the big show",
  },
];

const Portfolio = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchPortfolios = async () => {
    try {
      setLoading(true);
      const response = await axios(
        "https://cms.jtech.so/api/portfolios?sort[id]=desc&populate=*"
      );
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchPortfolios(), []);

  return (
    <>
      <PageHelmet pageTitle="Portfolio" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Portfolio"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--80 bg_color--5">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--10">
                    <span className="subtitle">Our Recent Works</span>
                    <h2 className="title">Latest Works</h2>
                    <p className="description">
                      We take pride in all of our work and we are proud at what
                      we do ♡
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                {loading ? (
                  <div style={{ width: "100%" }}>
                    <ShimmerPostList
                      postStyle="STYLE_FOUR"
                      col={3}
                      row={2}
                      gap={30}
                    />
                  </div>
                ) : (
                  <PortfolioList
                    portfolios={data?.data}
                    styevariation="text-left mt--40"
                    column="col-lg-4 col-md-6 col-sm-6 col-12"
                    item="12"
                  />
                )}
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-pagination text-center">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel=">"
                      onPageChange={(y) => {
                        console.log("Clicked ", y);
                      }}
                      activeLinkClassName="active"
                      containerClassName="page-list"
                      activeClassName="active"
                      pageRangeDisplayed={6}
                      pageCount={1}
                      previousLabel="<"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                  {/* <div className="view-more-btn mt--60 text-center">
                    <a className="btn-default" href="/portfolio">
                      <span>View More Project</span>
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area pt--90 pb--140 bg_color--1 d-none">
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="slick-space-gutter--15 slickdot--20">
                    <Slider {...slickDot}>
                      {list.map((value, index) => (
                        <div className="single_im_portfolio" key={index}>
                          <div className="im_portfolio">
                            <div className="thumbnail_inner">
                              <div className="thumbnail">
                                <Link to="/portfolio-details">
                                  {value.image}
                                </Link>
                              </div>
                            </div>
                            <div className="content">
                              <div className="inner">
                                <div className="portfolio_heading">
                                  <div className="category_list">
                                    <Link to="/portfolio-details">
                                      {value.category}
                                    </Link>
                                  </div>
                                  <h4 className="title">
                                    <Link to="/portfolio-details">
                                      {value.title}
                                    </Link>
                                  </h4>
                                </div>
                                <div className="portfolio_hover">
                                  <p>{value.description}</p>
                                </div>
                              </div>
                            </div>
                            <Link
                              className="transparent_link"
                              to="/portfolio-details"
                            ></Link>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};

export default Portfolio;

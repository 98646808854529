import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { kEndpoint } from "../../../config/constants";
import axios from "axios";
import { ShimmerPostItem, ShimmerPostList } from "react-shimmer-effects";
import PortfolioItem from "./PortfolioItem";

export default function Portfolio() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios(
        "https://cms.jtech.so/api/portfolios?pagination[limit]=3&sort[id]=desc&populate=*"
      );
      console.log(response.data?.data);
      setData(response.data?.data ?? []);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchData(), []);

  return (
    <>
      <div className="portfolio-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">Our Portfolio</span>
                <h2 className="title">Some of our Recent Works</h2>
                <p className="description">
                  We take pride in all of our work and we are proud at what we
                  do ♡
                </p>
              </div>
            </div>
          </div>

          <div className="row mt--30">
            {loading ? (
              <div style={{ width: "100%" }}>
                <ShimmerPostList
                  postStyle="STYLE_FOUR"
                  col={3}
                  row={1}
                  gap={30}
                />
              </div>
            ) : (
              data.map((item, index) => (
                <PortfolioItem item={item} key={index} />
              ))
            )}
          </div>
          {loading ? null : (
            <div className="mt--30 text-center">
              <Link className="rn-btn btn-border size-md" to="/portfolios">
                See More
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

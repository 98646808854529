import PropTypes from "prop-types";
import React, { Component } from "react";
import { Helmet } from "react-helmet";

class PageHelmet extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle} | Making your business flourish </title>
          <meta name="robots" content="noindex, follow" />
          <meta
            name="description"
            content="Jtech – Jamhuriya Technology Solutions - Jtech is a professional technology solution service provider and ICT training center founded in 2020 by Jamhuriya University of Science and Technology in Mogadishu, Somalia. Jtech has been established to fill the gap (of quality and creativity) in the field of ICT solutions in our nation and beyond by transforming clients’ business, operating and technology models for the digital era, and offering professional hands-on training to empower individuals and organizations in solving and innovating new ideas."
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
        </Helmet>
      </React.Fragment>
    );
  }
}
PageHelmet.propTypes = {
  title: PropTypes.string,
};
export default PageHelmet;

import React, { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";

import { FiCheck } from "react-icons/fi";

import { Redirect, useParams } from "react-router-dom";
import PageHelmet from "../../component/common/Helmet";
import Header from "../../component/header/Header";
import Footer from "../../component/footer/Footer";
import parse from "html-react-parser";
import { kEndpoint } from "../../config/constants";
import axios from "axios";
const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

const PortfolioDetail = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchPortfolioDetails = async () => {
    try {
      setLoading(true);
      const response = await axios(
        `https://cms.jtech.so/api/portfolios?filters[slug]=${params.slug}&populate=*`
      );

      const resData = Array.isArray(response.data?.data || {})
        ? response.data.data[0]
        : response.data.data;

      setData(resData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => fetchPortfolioDetails(), []);

  const [currOpen, setCurrOpen] = useState(null);

  const isVideoFile = (str) => [".mp4", ".mpg", ".avi", ".m4v"].includes(str);

  const openModal = (videoIDx) => setCurrOpen(videoIDx);

  if (!data && !loading) return <Redirect to="/404" />;

  let portfolioDetails = data?.attributes;

  return (
    <>
      <PageHelmet pageTitle="Portfolio Details" />

      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />

      {/* Start Breadcrump Area */}
      <div
        className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--4"
        data-black-overlay="7"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="rn-page-title text-center pt--100">
                <h2 className="title theme-gradient">
                  {portfolioDetails?.title}
                </h2>
                <p>{portfolioDetails?.subtitle} </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrump Area */}

      {/* Start Portfolio Details */}
      <div className="rn-portfolio-details ptb--120 bg_color--1">
        <div className="container">
          {parse(portfolioDetails?.description ?? "")}
        </div>
      </div>
      {/* End Portfolio Details */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      <Footer />
    </>
  );
};
export default PortfolioDetail;

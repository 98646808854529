import React, { Component, Fragment } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { kEndpoint } from "../../config/constants";

const BLogList = (props) => {
  const blogs = props.data?.data || [];
  const { meta } = props.data || {};

  return (
    <Fragment>
      <div className="row mt_dec--30">
        {blogs.map(({ attributes: value }, i) => (
          <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt--30" key={i}>
            <div className="im_box">
              <div className="thumbnail">
                <Link to={`blogs/${value.slug}`}>
                  <img
                    className="w-100"
                    src={`${kEndpoint}${value.featured_image?.data?.attributes?.url}`}
                    alt="Blog Images"
                  />
                </Link>
              </div>
              <div className="content">
                <div className="inner">
                  <div className="content_heading">
                    <div className="category_list">
                      <Link to={`blogs/${value.slug}`}>
                        {value.category?.join(", ")}
                      </Link>
                    </div>
                    <h4 className="title">
                      <Link to={`blogs/${value.slug}`}>{value.title}</Link>
                    </h4>
                  </div>
                  <div className="content_footer">
                    <Link
                      to={`blogs/${value.slug}`}
                      className="rn-btn btn-opacity"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                <Link
                  className="transparent_link"
                  to={`blogs/${value.slug}`}
                ></Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt--60">
        <div className="col-lg-12">
          {/* Start Pagination Area */}
          <div className="rn-pagination text-center">
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={(y) => {
                console.log("Clicked ", y);
              }}
              activeLinkClassName="active"
              containerClassName="page-list"
              activeClassName="active"
              pageRangeDisplayed={6}
              pageCount={1}
              previousLabel="<"
              renderOnZeroPageCount={null}
            />
          </div>
          {/* End Pagination Area */}
        </div>
      </div>
    </Fragment>
  );
};
export default BLogList;

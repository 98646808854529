import React, { Component, Fragment } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { FiHeart, FiClock, FiCheckCircle, FiAward } from "react-icons/fi";

import HappyCustomers from "../../assets/images/positive-vote1.png";
import project1 from "../../assets/images/document1.png";
import project2 from "../../assets/images/briefing.png";

class CounterOne extends Component {
  state = {
    didViewCountUp: false,
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };
  render() {
    let Data = [
      {
        icon: <img src={HappyCustomers} height={50} />,
        countNum: 290,
        countTitle: "Happy Customers",
      },

      {
        icon: <img src={project2} height={50} />,
        countNum: 185,
        countTitle: "Completed Projects",
      },

      {
        icon: <FiCheckCircle />,
        countNum: 25,
        countTitle: "Our Services",
      },
      {
        icon: <FiAward />,
        countNum: new Date().getFullYear() - 2018,
        countTitle: "Years of Experience",
      },
    ];

    return (
      <Fragment>
        <div className="row mt--30">
          {Data.map((value, index) => (
            <div
              className="im_single_counterup col-lg-3 col-md-4 col-sm-6 col-12"
              key={index}
            >
              <div className="im_counterup">
                <div className="inner">
                  <div className="icon">{value.icon}</div>
                  <h2 className="counter">
                    <VisibilitySensor
                      onChange={this.onVisibilityChange}
                      offset={{ top: 10 }}
                      delayedCall
                    >
                      <CountUp
                        end={this.state.didViewCountUp ? value.countNum : 0}
                      />
                    </VisibilitySensor>
                  </h2>
                  <p className="description">{value.countTitle}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    );
  }
}
export default CounterOne;

import React from "react";
import Slider from "react-slick";
import { SUB_DOMAIN } from "../../../data/constants";
import { slideSlick } from "../../../page-demo/script";

const SlideList = [
  {
    textPosition: "text-left",
    bgImage: "bg_image--34",
    category: "",
    title: "Creativity",
    description: "Let's make your 𝗕𝗿𝗮𝗻𝗱 alive🔥 and see the 𝗠𝗮𝗴𝗶𝗰✨",
    buttonText: "Contact Us",
    buttonLink: "/help",
  },
  {
    textPosition: "text-right",
    bgImage: "bg_image--35",
    category: "",
    title: "Software Dev.",
    description: "Making your business flourish through modern tech.",
    buttonText: "Contact Us",
    buttonLink: "/help",
  },
  {
    textPosition: "text-center",
    bgImage: "bg_image--18",
    category: "",
    title: "ICT Trainings",
    description:
      "Get trained with us to gain career advancement in technology.",
    buttonText: "Get Enrolled",
    buttonLink: SUB_DOMAIN,
  },
];
export default function HeroSection() {
  return (
    <div className="slider-wrapper z-1">
      <div className="slider-activation">
        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
          {SlideList.map((value, index) => (
            <div
              className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
              key={index}
              data-black-overlay="5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      {value.title ? (
                        <h1 style={{ font: "Overpass" }} className="title">
                          {value.title}
                        </h1>
                      ) : (
                        ""
                      )}
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="btn-default"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb(props) {
  const { title, parent } = props;

  return (
    <>
      <div className="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner pt--100">
                <h2 className="title">{title}</h2>
                <ul className="page-list">
                  <li className="rn-breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {parent ? (
                    <li className="rn-breadcrumb-item">{parent}</li>
                  ) : (
                    ""
                  )}
                  <li className="rn-breadcrumb-item active">{title}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

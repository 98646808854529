import isValidDomain from "is-valid-domain";
import React, { useRef, useState } from "react";
import { FaSearch } from "react-icons/fa";

import domains from "../../../data/domains.json";

export default function SearchDomain() {
  const domainRef = useRef(null);
  const [error, setError] = useState(false);

  const HandleDomainSearch = (e) => {
    e.preventDefault();
    let url =
      "https://host.jtech.so/cart.php?a=add&domain=register&query=" +
      domainRef.current.value;
    var win = window.open(url, "_blank");
    win.focus();
  };

  return (
    <div className="container py-5  tw-bg-blue-400  -mt-25  z-2  rounded-2">
      <div className="row justify-content-center padding">
        <div className="col-md-12">
          <form
            onSubmit={HandleDomainSearch}
            action="#"
            className="domain-form d-flex rounded-pill bg-white border-1 p-2"
          >
            <div className="form-group d-md-flex  flex-fill mb-0">
              <div className="input-group d-inline-flex d-md-flex">
                <span className="input-group-text bg-transparent border-0 tx-22 d-none d-lg-block">
                  www.
                </span>
                <input
                  required
                  ref={domainRef}
                  type="text"
                  className="form-control tx-22 h-100 border-0 shadow-none bg-transparent"
                  placeholder="Enter your domain name..."
                />
              </div>
            </div>
            <button
              type="submit"
              className="btn-default d-flex align-items-center text-lowercase p-2 px-md-4 py-md-2"
            >
              <FaSearch className="m-0 mr-md-2" />
              <span className="d-none d-lg-block">Search</span>
            </button>
          </form>
          <p className="domain-price text-center mt-3">
            {domains.map((domain, idx) => (
              <span key={idx}>
                <span>{domain.domain}</span>${domain.price}
              </span>
            ))}
          </p>
        </div>
      </div>
    </div>
  );
}

exports.hostingPlans = [
  {
    id: 1,
    title: "Startup",
    subtitle: "USD Per Year",
    url: 'https://host.jtech.so/index.php?rp=/store/shared-hosting/startup',
    regularPrice: 65,
    salePrice: 50,
    priceSymbol: "$",
    recursion: "Yearly",
    isPopular: false,
    services: [
      "20 GB SSD Storage",
      "Unlimited Bandwidth",
      "10 Databases",
      "10 Email Accounts",
      "Free SSL Certificates",
      "99.9% Server running",
      "Unlimited Parked/Subdomains",
      "Free Website Caching",
      "And more...",
    ],
  },
  {
    id: 2,
    title: "Business",
    subtitle: "USD Per Year",
    url: 'https://host.jtech.so/index.php?rp=/store/shared-hosting/business',
    regularPrice: 75,
    salePrice: 60,
    priceSymbol: "$",
    recursion: "Yearly",
    isPopular: true,
    services: [
      "50 GB SSD Storage",
      "Unlimited Bandwidth",
      "10 Databases",
      "30 Email Accounts",
      "Free SSL Certificates",
      "99.9% Server running",
      "Unlimited Parked/Subdomains",
      "Free Website Caching",
      "And more...",
    ],
  },
  {
    id: 3,
    title: "Corporate",
    subtitle: "USD Per Year",
    url: 'https://host.jtech.so/index.php?rp=/store/shared-hosting/corporate',
    regularPrice: 85,
    salePrice: 75,
    priceSymbol: "$",
    recursion: "Yearly",
    isPopular: false,
    services: [
      "Unlimited SSD Storage",
      "Unlimited Bandwidth",
      "Unlimited Databases",
      "Unlimited Email Accounts",
      "Free SSL Certificates",
      "99.9% Server running",
      "Unlimited Parked/Subdomains",
      "Free Website Caching",
      "And more...",
    ],
  },
];
